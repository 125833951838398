import "./App.css"
import { useEffect } from "react"

import { getVideos } from "./services/firestore"

function App() {
  useEffect(() => {
    getVideos()
  }, [])

  return (
    <div className="App">
      <header className="App-header">
        <h1>MIERCOLES</h1>
      </header>
    </div>
  )
}

export default App
