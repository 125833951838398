import { initializeApp } from "firebase/app"
import {
  getFirestore,
  collection,
  getDocs,
  setDoc,
  doc,
  getDoc,
} from "firebase/firestore"
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyBzRqc-EI3HxBCKPp7B28Nr29QbZUUsE-s",
  authDomain: "miercoles-727af.firebaseapp.com",
  projectId: "miercoles-727af",
  storageBucket: "miercoles-727af.appspot.com",
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

export const login = (username, password) => {
  return signInWithEmailAndPassword(getAuth(), username, password)
}

export const currentUser = async () => {
  const auth = getAuth()
  return new Promise(function (resolve, reject) {
    onAuthStateChanged(auth, (user) => {
      resolve(user)
    })
  })
}

export const logout = () => {
  const auth = getAuth()
  return signOut(auth)
}

export const allUsers = async () => {
  const querySnapshot = await getDocs(collection(db, "users"))
  querySnapshot.forEach((doc) => {
    console.log(`${doc.id} => ${doc.data().firstname}`)
  })
}

export const getUserInfo = async () => {
  const user = getAuth().currentUser.uid
  const docRef = doc(db, "users", user)
  const docSnap = await getDoc(docRef)

  if (docSnap.exists()) {
    return docSnap.data()
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!")
  }
}

export const register = async (email, password, firstName, lastName) => {
  const auth = getAuth()
  await createUserWithEmailAndPassword(auth, email, password)
  await setDoc(doc(db, "users", getAuth().currentUser.uid), {
    firstname: firstName,
    lastname: lastName,
  })
}

export const updateProfile = async (
  firstName,
  lastName,
  birthdate,
  telephone
) => {
  await setDoc(doc(db, "users", getAuth().currentUser.uid), {
    firstname: firstName,
    lastname: lastName,
    birthdate,
    telephone,
  })
}

export const getVideos = async () => {
  const querySnapshot = await getDocs(collection(db, "videos"))
  querySnapshot.forEach((doc) => {
    console.log(`${doc.id} => ${doc.data().naam}`)
  })
}
